<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter clear-filter"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="brand">
              <h1>
                PolyPlanks
              </h1>
              <h3 class="title">
                A limited edition of 100 Unique NFT's on the Polygon (MATIC) blockchain.
                <div>&nbsp;</div>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </parallax>
    <div
      id="main-panel"
      class="main main-raised">
      
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  name: "Index",
  components: {
  
  },
  mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
  bodyClass: "index-page",
  props: {
    image: {
      type: String,
      default: require("@/assets/img/cloudsbg.png")
    }
  },
  mounted() {
   
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
  }
};
</script>
<style lang="scss">
.vertical-nav-active {
  display: block;
}

@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}
@media all and (max-width: 768px) {
  .vertical-nav-active {
    display: none;
  }
}

.mb-0 {
  padding-bottom: 0 !important;
}

#morphing-cards {
  padding-top: 70px;
}

@keyframes float {
	0% {
		text-shadow: 0 5px 15px 0px rgba(255,255,255,0.6);
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-1rem);
	}
	100% {
		transform: translatey(0px);
	}
}

.index-page{
  .md-layout{
    .brand{
      animation: float 4s ease-in-out infinite;
      background: rgba(9,75,119, 20%);
      padding: 2rem;

      h1{
        text-transform: uppercase;
        font-family: 'pixelmaniaregular', sans-serif;
        font-size: 4rem;
        text-shadow: none;
        // text-shadow:0px 15px 50.86px rgb(9 75 119 / 70%), 0px 15px 20.02px rgb(9 75 119 / 21%);   
        z-index: 4;     
      }

      h3{
        z-index: 5;
      }
    }
  }
}
</style>
